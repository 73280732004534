
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import SingleDateSelector from './components/SingleDateSelector.vue';

export default defineComponent({
    name: "Sign Up",
    components: {
        IonPage, IonContent, IonGrid, IonRow, IonCol,
        SingleDateSelector
    },
    data() {
        return {
            isUpload: false,
            selectedUser: {
                user_data: {
                    username: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    groups: [
                        "Subscriber"
                    ]
                },
                date_birth: null,
                gender: 'X',
                user_img: null,
                address: null,
                telephone_number: null,
                identity_document_type: null,
                identity_document: null,
                user_type: null
            } as any,
            userFilter: '',
            status: '',
            searchUser: false,
            users: [] as any[],
            utypes: [] as any[],
            showForm: false,
            resetPassword: false
        }
    },
    ionViewDidEnter() {
        axios.get(''.concat(config['host_ip'], config['host_port'], "/usertypes/"))
        .then((response) => {
            this.utypes = response.data
        }).catch((error) => { console.log(error) });
    },
    computed: {
        getImageURL(): any {
            return this.selectedUser.user_img
        }
    },
    methods: {
        resetVariables() {
            this.isUpload = false
            this.selectedUser = {
                user_data: {
                    username: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    groups: [
                        "Subscriber"
                    ]
                },
                date_birth: null,
                gender: 'X',
                user_img: null,
                address: null,
                telephone_number: null,
                identity_document_type: null,
                identity_document: null,
                user_type: null
            } as any
            this.userFilter = ''
            this.status = ''
            this.searchUser = false
            this.users = [] as any[]
            this.showForm = false
            this.resetPassword = false
        },
        getDateBirth(res: any) {
            this.selectedUser.date_birth = res
        },
        fileUpload(fileInput: any) {
            if (fileInput.target.files.length > 0) {
                const fileData = (fileInput.target.files[0]);

                if (fileData.type.match(/image\/*/) == null) {
                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(fileData);
                reader.onload = () => {
                    this.isUpload = true
                    this.selectedUser.user_img = reader.result;
                };
            }
        },
        save() { 

            const payload = JSON.parse(JSON.stringify(this.selectedUser))
            if(!this.isUpload) {
                delete payload.user_img
            }

            axios.post(''.concat(config['host_ip'], config['host_port'], "/user-create/"), payload)
            .then((response) => {
                this.parseQueryResult(response)
            })
            .catch((error) => { this.parseQueryResult(error.response) });
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                
                this.resetVariables()
                this.status = 'Registration Confirmed. Credentials have been sent to your email and/or telephone via SMS.'
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{\[\]}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.statusText)
            }
        },
        goBack() {
            this.resetVariables()
            this.$router.push('/')
        },
        checkField(field: string) {
            return field && field.length > 0
        }
    }
})
