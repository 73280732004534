
import {IonIcon} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {IonIcon},
  props: {
    inputDate: String
  },
  emits: ['rangeinfo'],
  data() {
      return {
          step: 0,
          dt: null as any,
          mask: {
            input: 'DD/MM/YYYY',
          }
      }
  }, 
  mounted() {
    if (this.$props.inputDate) {
      this.dt = new Date(this.$props.inputDate)
    }
  },
  methods: {
      sendBack() {
        const date = new Date(this.dt)
        const year = date.getFullYear().toString()
        const month = (date.getMonth() + 1).toString()
        const day = date.getDate().toString()
        this.$emit('rangeinfo',  year + '-' + month + '-' + day)
      }
  },
    watch: {
        dt: {
            deep: true,
            handler: function(newVal) {
              console.log(newVal)
              this.sendBack()
            }
        },
        inputDate: {
          handler: function(newVal) {
            this.dt = new Date(newVal)
          }
        }
    }
});
