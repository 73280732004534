<template>
  <v-date-picker :masks="mask" v-model="dt" :input-debounce="500">
    <template v-slot="{ inputValue, togglePopover }">
      <div class="flex" @click="togglePopover()">
        <ion-icon name="calendar"></ion-icon>
        <input class="searchbar" :value="inputValue" readonly style="border: none; cursor: auto; text-align: start;" />
      </div>
    </template>
  </v-date-picker>
</template>


<script lang="ts">
import {IonIcon} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {IonIcon},
  props: {
    inputDate: String
  },
  emits: ['rangeinfo'],
  data() {
      return {
          step: 0,
          dt: null as any,
          mask: {
            input: 'DD/MM/YYYY',
          }
      }
  }, 
  mounted() {
    if (this.$props.inputDate) {
      this.dt = new Date(this.$props.inputDate)
    }
  },
  methods: {
      sendBack() {
        const date = new Date(this.dt)
        const year = date.getFullYear().toString()
        const month = (date.getMonth() + 1).toString()
        const day = date.getDate().toString()
        this.$emit('rangeinfo',  year + '-' + month + '-' + day)
      }
  },
    watch: {
        dt: {
            deep: true,
            handler: function(newVal) {
              console.log(newVal)
              this.sendBack()
            }
        },
        inputDate: {
          handler: function(newVal) {
            this.dt = new Date(newVal)
          }
        }
    }
});
</script>

<style scoped>
.ds-grid {
    display: grid;
}

.right-inner-addon {
    position: relative;
    width: 100%;
}
.right-inner-addon input {
    width: 8.6vw;
}
.right-inner-addon svg {
    position: absolute;
    right: 18px;
    pointer-events: none;
    top: 2.8vh;
    width: 10%;
}

.input-date {
    background-color: var(--ion-background-color) !important;
    border-radius: 5px;
    border: 1px solid rgba(60,60,60,.26);
    padding-left: 5px;
    margin-top: 5px;
}

.rsz {
    transform: scale(0.65);
}

</style>
