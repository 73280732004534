<template>
    <ion-page>
        <ion-content>
            <ion-grid class="full-height">
                <ion-row class="mt-10">
                    <ion-col size-xl="3" size-sm="0.1"></ion-col>
                    <ion-col  size-xl="6" size-sm="11.8">
                        <div class="card center" style="height: auto">
                            <b>Sign Up</b>
                            <div class="form">
                                <div class="scale90p mt-2">
                                    <div class="flex">
                                        <div class="column">
                                            <div class="input-float-label input-bottom mt-2">
                                                <input class="searchbar input-field" type="text" v-model="selectedUser.user_data.username" />
                                                <label class="floating-label" :class="selectedUser?.user_data?.username?.length > 0 ? 'input-filled' : ''">Username</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2">
                                                <input class="searchbar input-field" type="text" v-model="selectedUser.address" />
                                                <label class="floating-label" :class="selectedUser?.address?.length > 0 ? 'input-filled' : ''">Address</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2">
                                                <input class="searchbar input-field" type="tel" v-model="selectedUser.telephone_number" />
                                                <label class="floating-label" :class="selectedUser?.telephone_number?.length > 0 ? 'input-filled' : ''">Telephone Number</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2">
                                                <input class="searchbar input-field" type="text" v-model="selectedUser.user_data.email" />
                                                <label class="floating-label" :class="selectedUser?.user_data?.email?.length > 0 ? 'input-filled' : ''">Email</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2 flex">
                                                <select class="searchbar input-field" name="gender" id="gender" v-model="selectedUser.gender">
                                                    <option value="M">Male</option>
                                                    <option value="F">Female</option>
                                                    <option value="X">Other</option>
                                                </select>
                                                <label class="floating-label" :class="selectedUser?.gender?.length > 0 ? 'input-filled' : ''">Gender</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2">
                                                <single-date-selector class="input-field" @rangeinfo="getDateBirth" :inputDate="selectedUser.date_birth"></single-date-selector>
                                                <label class="floating-label ml-1" :class="selectedUser?.date_birth?.length > 0 ? 'input-filled' : ''">Date of Birth</label>
                                            </div>
                                        </div>
                                        <div class="column" style="margin-left:10%">
                                            <div class="input-float-label input-bottom mt-2">
                                                <input class="searchbar input-field" type="text" v-model="selectedUser.user_data.first_name" />
                                                <label class="floating-label" :class="selectedUser?.user_data?.first_name?.length > 0 ? 'input-filled' : ''">First Name</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2">
                                                <input class="searchbar input-field" type="text" v-model="selectedUser.user_data.last_name" />
                                                <label class="floating-label" :class="selectedUser?.user_data?.last_name?.length > 0 ? 'input-filled' : ''">Last Name</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2">
                                                <input class="searchbar input-field" type="text" v-model="selectedUser.identity_document_type" />
                                                <label class="floating-label" :class="selectedUser?.identity_document_type?.length > 0 ? 'input-filled' : ''">Document Type</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2">
                                                <input class="searchbar input-field" type="text" v-model="selectedUser.identity_document" />
                                                <label class="floating-label" :class="selectedUser?.identity_document?.length > 0 ? 'input-filled' : ''">Document Number</label>
                                            </div>
                                            <div class="input-float-label input-bottom mt-2 flex">
                                                <select class="searchbar input-field" name="utype" id="utype" v-model="selectedUser.user_type">
                                                    <option v-for="ut of utypes" :key=ut :value="ut.user_type">{{ ut.user_type }}</option>
                                                </select>
                                                <label class="floating-label" :class="checkField(selectedUser?.user_type) ? 'input-filled' : ''">User Type</label>
                                            </div>
                                            <div class="mt-2" style="vertical-align: middle; display: flex;">
                                                <input ref="infile" style="display: none" type="file" accept="image/*" @change="fileUpload($event)">
                                                <img class="zoom" v-if="selectedUser.user_img?.length > 0" :src="getImageURL" style="width:50px; margin-left: 50px"/>
                                                <button class="btn-custom2" @click="$refs.infile.click()" style="margin-left:auto; margin-top:auto; margin-bottom:auto;">Upload Profile Pic</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2" style="text-align: center; min-height: 50px; color:red">
                            {{ status }}
                        </div>
                        <div class="mt-2" style="text-align: center">
                            <button class="btn-custom bold-text" @click="save()">Submit</button>
                            <button class="btn-custom" @click="goBack()">Back</button>
                        </div>
                    </ion-col>
                    <ion-col size-xl="3" size-sm="0.1"></ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import SingleDateSelector from './components/SingleDateSelector.vue';

export default defineComponent({
    name: "Sign Up",
    components: {
        IonPage, IonContent, IonGrid, IonRow, IonCol,
        SingleDateSelector
    },
    data() {
        return {
            isUpload: false,
            selectedUser: {
                user_data: {
                    username: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    groups: [
                        "Subscriber"
                    ]
                },
                date_birth: null,
                gender: 'X',
                user_img: null,
                address: null,
                telephone_number: null,
                identity_document_type: null,
                identity_document: null,
                user_type: null
            } as any,
            userFilter: '',
            status: '',
            searchUser: false,
            users: [] as any[],
            utypes: [] as any[],
            showForm: false,
            resetPassword: false
        }
    },
    ionViewDidEnter() {
        axios.get(''.concat(config['host_ip'], config['host_port'], "/usertypes/"))
        .then((response) => {
            this.utypes = response.data
        }).catch((error) => { console.log(error) });
    },
    computed: {
        getImageURL(): any {
            return this.selectedUser.user_img
        }
    },
    methods: {
        resetVariables() {
            this.isUpload = false
            this.selectedUser = {
                user_data: {
                    username: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    groups: [
                        "Subscriber"
                    ]
                },
                date_birth: null,
                gender: 'X',
                user_img: null,
                address: null,
                telephone_number: null,
                identity_document_type: null,
                identity_document: null,
                user_type: null
            } as any
            this.userFilter = ''
            this.status = ''
            this.searchUser = false
            this.users = [] as any[]
            this.showForm = false
            this.resetPassword = false
        },
        getDateBirth(res: any) {
            this.selectedUser.date_birth = res
        },
        fileUpload(fileInput: any) {
            if (fileInput.target.files.length > 0) {
                const fileData = (fileInput.target.files[0]);

                if (fileData.type.match(/image\/*/) == null) {
                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(fileData);
                reader.onload = () => {
                    this.isUpload = true
                    this.selectedUser.user_img = reader.result;
                };
            }
        },
        save() { 

            const payload = JSON.parse(JSON.stringify(this.selectedUser))
            if(!this.isUpload) {
                delete payload.user_img
            }

            axios.post(''.concat(config['host_ip'], config['host_port'], "/user-create/"), payload)
            .then((response) => {
                this.parseQueryResult(response)
            })
            .catch((error) => { this.parseQueryResult(error.response) });
        },
        parseQueryResult(resp: any) {
            console.log(resp)
            console.log(resp.status)
            if (resp?.status == 201) {
                
                this.resetVariables()
                this.status = 'Registration Confirmed. Credentials have been sent to your email and/or telephone via SMS.'
            }
            else if (resp?.status == 400) {
                this.status = JSON.stringify(resp.data).replaceAll(/[{\[\]}_]/g, ' ')
            }
            else {
                this.status = JSON.stringify(resp.statusText)
            }
        },
        goBack() {
            this.resetVariables()
            this.$router.push('/')
        },
        checkField(field: string) {
            return field && field.length > 0
        }
    }
})
</script>

<style scoped>
.form {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
}

.right-btn {
    cursor: pointer;
    display: flex;
    right: 0;
    position: absolute;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.info-reward {
    border: 1px solid #81b9ee;
    padding: 5px;
    text-align: start;
}

.add-icon {
    color: gray;
    --ionicon-stroke-width: 3.1vw;
    text-align: end;
}

tr {
    height: 2.7vh;
}

.checkbox {
    outline: 2px solid #81b9ee;
}

.column {
    width: 45%
}

.zoom:hover {
    transform: scale(5);
    transform-origin: top;
}
</style>
